
          $env: 'production';
          @import '/var/build/src/styles/utils/index';

        
































































.archive {
  // prettier-ignore
  @include fluid(padding-top, (mob: 160px, xl: 300px));
}

.archive__list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 30px;

  @include mq(s) {
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    grid-gap: 40px;

    .card {
      margin-bottom: 30px;
    }

    @include mq($until: xl) {
      &::v-deep .card__title {
        width: 90%;
      }
    }
  }
}

.highlight__intro {
  position: relative;
}

.highlight__layer {
  @include get-all-space;

  background: $school-bus-yellow;

  img {
    @include image-fit;
  }
}

.highlight__text {
  position: relative;
  z-index: 5;
}

.highlight__title {
  // prettier-ignore
  @include fluid(font-size, (mob: 48px, xl: 100px));

  line-height: 1;
}

.highlight__baseline {
  ::v-deep > * {
    @include manier;
    // prettier-ignore
    @include fluid(font-size, (xxs: 24px, m: 36px));

    line-height: 1.2;
  }
}

@include mq(s) {
  .highlight {
    display: flex;
  }

  .highlight__intro {
    flex-basis: 60%;
    margin: 0;
  }

  .highlight__text {
    // prettier-ignore
    @include fluid(padding-top, (s: 20px, xl: 120px));

    max-width: 800px;
    margin-right: 0;
  }

  .highlight__content {
    // prettier-ignore
    @include fluid(padding-left, (s: 40px, xl: 70px));
    // prettier-ignore
    @include fluid(padding-right, (s: 40px, xl: 70px, ) );
    // prettier-ignore
    @include fluid(padding-top, (s: 60px, xl: 140px));

    flex-basis: 40%;
    padding-bottom: 30px;

    .card {
      max-width: 530px;
    }
  }

  @include mq(l) {
    .highlight__intro {
      flex-basis: 50%;
    }

    .highlight__content {
      flex-basis: 50%;
    }
  }
}

.filter {
  position: relative;
  border: solid $alto;
  border-width: 1px 0;

  @include mq($until: s) {
    margin-top: 7.5rem;
  }
}

.filter__trigger {
  // prettier-ignore
  @include fluid(font-size, (xxs: 13px, m: 15px));
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, m: 55px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 55px));

  display: flex;
  align-items: center;
  font-weight: 700;
  text-transform: uppercase;
  background: transparent;
  border: solid $alto;
  border-width: 0 1px;
  cursor: pointer;
}

.filter__trigger__icon {
  width: 1.8rem;
  height: 1.1rem;
  margin-left: 1rem;
}

.filter__list {
  // prettier-ignore
  @include fluid(padding-top, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-right, (xxs: 20px, m: 50px));
  // prettier-ignore
  @include fluid(padding-bottom, (xxs: 20px, m: 40px));
  // prettier-ignore
  @include fluid(padding-left, (xxs: 20px, m: 50px));

  position: absolute;
  z-index: 10;
  top: 100%;
  width: auto;
  height: auto;
  font-weight: 300;
  background: $c-white;
  border: 1px $alto solid;
}

.filter__list__item {
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}
